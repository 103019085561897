<template>
  <section class="w-full h-full flex flex-col items-center">
    <div class="w-1/2 lg:flex justify-center flex-col flex-shrink-1 items-center">
      <div class="p-10">
        <h1 class="text-center xs:text-lg sm:text-xl md:text-2xl lg:text-4xl text-black leading-normal">
          {{ $t('user.welcome') }}
        </h1>
        <p class="xs:text-xs sm:text-xs md:text-xs lg:text-sm xl:text-base text-grey-darker text-center mt-5 pl-2">
          {{ $t('user.subwelcome') }}
        </p>
      </div>
    </div>
    <div class="shadow bg-white flex-shrink-0 xs:w-3/4 sm:w-3/4 md:w-1/2 max-w-md">
      <div class="h-full overflow-auto xs:py-5 sm:py-10 md:py-10 lg:py-16 flex flex-col items-center">
        <el-form
          v-if="!isSubmit"
          ref="ruleForm"
          label-position="top"
          :model="form"
          :rules="rules"
          class="form-container w-3/4"
          @submit.native.prevent="handleSubmit('ruleForm')"
        >
          <div class="text-center mb-12">
            <Logo />
          </div>
          <h2 class="text-black text-lg mb-5">
            {{ $t('user.forgot_password') }}
          </h2>
          <div class="block">
            <p class="mb-1 text-sm font-medium text-grey-darker required">
              {{ $t('fields.email') }}
            </p>
            <el-form-item
              class="leading-normal"
              prop="username"
            >
              <el-input
                v-model="form.username"
                :placeholder="$t('user.placeholders[0]')"
              />
            </el-form-item>
          </div>

          <el-form-item>
            <el-button
              :loading="loader"
              type="primary"
              native-type="submit"
              class="login-btn w-full mt-5"
            >
              {{ $t('user.reset_password') }}
            </el-button>
          </el-form-item>

          <hr>

          <el-form-item>
            <el-button
              class="login-btn w-full"
              :disabled="loader"
              @click="login()"
            >
              {{ $t('user.go_back_login') }}
            </el-button>
          </el-form-item>
        </el-form>
        <div
          v-else
          class="form-container lg:px-10"
        >
          <div class="text-center mb-12">
            <Logo />
          </div>
          <h2 class="text-black text-lg mb-5">
            {{ $t('user.check_email[0]') }}
          </h2>
          <p class="mb-5 text-sm font-medium text-grey-darker leading-normal">
            {{ $t('user.check_email[1]') }}
            <span class="text-black font-bold">{{ form.username || 'your email' }}</span>.
            {{ $t('user.check_email[2]') }}
          </p>

          <p class="mb-5 text-sm font-medium text-grey-darker leading-normal">
            {{ $t('user.check_email[3]') }}
          </p>

          <a
            class="mt-10 text-blue no-underline text-sm inline-block cursor-pointer"
            @click.prevent="emailForm"
          >
            {{ $t('user.check_email[4]') }}
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import http from '../../../utils/http';
import Logo from '../components/Logo.vue';

export default {
  name: 'ForgotPassword',

  components: {
    Logo,
  },

  data() {
    const self = this;
    return {
      isSubmit: false,

      loader: false,

      form: {
        username: '',
      },

      token: null,

      rules: {
        username: [
          { required: true, message: self.$t('validations.required', { value: self.$t('fields.email') }), trigger: 'change' },
          { type: 'email', message: self.$t('validations.valid', { value: self.$t('fields.email') }), trigger: 'change' },
        ],
      },
    };
  },

  methods: {
    handleSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          return;
        }

        // Show spinner
        this.loader = true;

        this.$recaptchaLoaded()
          .then(() => this.$recaptcha('forgot_password'))
          .then(token => http.v1.post('auth/forgot-password', {
            email: this.form.username,
            gToken: token,
            version: 'v3',
          })).then(() => {
            this.isSubmit = true;
            this.loader = false;
          })
          .catch((err) => {
            const { data = {} } = err;

            if (data.error && data.error.match(/sso_configured/i)) {
              const email = this.form.username;

              this.$showError(this, err, { useMessage: this.$t('errors.saml_enabled'), reloadUrl: `/login/sso?email=${email}` });
            } else {
              this.$showError(this, err, { useMessage: this.$t('errors.invalid'), reloadUrl: '/login/forgot-password' });
            }
          })
          .finally(() => {
            this.loader = false;
          });
      });
    },


    login() {
      this.$router.push({ path: '/' });
    },

    emailForm() {
      this.form.username = '';
      this.isSubmit = false;
    },
  },
};
</script>

<style>
 .form-container {
 }

 .form-container .el-form-item__label {
    padding: 0;
 }
</style>
