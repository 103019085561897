//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';
import singleSpa from 'single-spa';
import Logo from '../components/Logo.vue';
import http from '../../../utils/http';

export default {
  name: 'ResetPassword',

  components: {
    Logo,
  },

  data() {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('validations.required', { value: this.$t('fields.password') })));
      } else if (value.length <= 7 || value.length >= 49) {
        callback(new Error(this.$t('validations.between_char_limit', { value: '8 to 50' })));
      } else if (!value.match(/^(?=.*[a-zA-Z])/g)) {
        callback(new Error(this.$t('validations.atleast_one_letter')));
      } else if (!value.match(/^(?=.*[0-9])/g)) {
        callback(new Error(this.$t('validations.atleast_one_number')));
      } else if (!value.match(/^(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])/g)) {
        callback(new Error(this.$t('validations.atleast_one_special_char')));
      } else if (this.strength < 75) {
        callback(new Error(this.$t('validations.weak_password')));
      } else if (this.form.checkPass !== '') {
        this.$refs.ruleForm.validateField('checkPass');
      } else {
        callback();
      }
    };
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('validations.input_again', { value: this.$t('fields.password') })));
      } else if (value !== this.form.pass) {
        callback(new Error(this.$t('validations.not_matched', { value: this.$t('fields.password') })));
      } else {
        callback();
      }
    };

    return {
      score: 0,
      loader: false,
      loginEmail: '',
      form: {
        pass: '',
        checkPass: '',
      },

      rules: {
        pass: [
          {
            validator: validatePass,
            type: 'password',
            required: true,
            trigger: 'blur',
          },
        ],
        checkPass: [
          {
            validator: validatePass2,
            type: 'password',
            required: true,
            trigger: 'blur',
          },
        ],
      },
    };
  },

  computed: {
    strength() {
      return this.score * 25;
    },
    status() {
      if (this.score < 3) {
        return 'weak';
      }

      if (this.score < 4) {
        return 'good';
      }

      return 'strong';
    },
  },

  beforeRouteEnter(to, from, next) {
    next(vm => vm.validateResetToken());
  },

  methods: {
    ...mapActions({
      resetPassword: 'users/resetPassword',
    }),

    handleSubmit() {
      // Show spinner
      this.loader = true;

      this.resetPassword({
        token: this.$route.params.token,
        password: this.form.pass,
        email: this.loginEmail,
      })
        .then(() => {
          this.loader = false;
          this.showMessage(this.$t('success.updated', { value: this.$t('fields.password') }), 'success');
          singleSpa.navigateToUrl('/');
          // window.location = '/';
        })
        .catch((err) => {
          // if (window.Bugsnag) {
          //   window.Bugsnag.notify(e);
          // }

          let msg = '';
          this.loader = false;

          if (err.message && err.message.match(/8 char/i)) {
            msg = this.$t('validations.between_char_limit', { value: '8 to 50' });
          } else if (err.message && err.message.match(/INVALID_TOKEN/)) {
            msg = this.$t('errors.token_expired');
          } else {
            msg = this.$t('errors.500');
          }

          if (msg) {
            this.$showError(this, err, { useMessage: msg });
          } else {
            this.$showError(this, err);
          }

          // this.showMessage(msg, 'error');
        });
    },

    showMessage(message, type) {
      this.$message({
        title: 'Error',
        message,
        type,
      });
    },

    validateResetToken() {
      // check if coming from password expiry
      if (this.$route.params.feedback) {
        this.showMessage(`${this.$t('user.password_expiring[2]')}. ${this.$t('user.password_expiring[3]')}`, 'error');
      }

      http.v1.post('auth/validate-reset-token', {
        token: this.$route.params.token,
      }).then((result) => {
        const { Login } = result.data;
        this.loginEmail = Login;
      })
        .catch(() => {
          this.$router.push({ path: '/' });
        });
    },

    login() {
      this.$router.push({ path: '/' });
    },
    check() {
      const result = this.$zxcvbn3(this.form.pass);
      this.score = result.score;
    },
  },
};
