<template>
  <section
    v-if="!isSubmitted"
    class="w-full h-full flex flex-col items-center"
  >
    <div
      class="w-1/2 lg:flex justify-center flex-col flex-shrink-1 items-center"
    >
      <div class="p-10">
        <h1 class="text-center xs:text-lg sm:text-xl md:text-2xl lg:text-4xl text-black leading-normal">
          {{ $t('user.welcome') }}
        </h1>
        <p class="xs:text-xs sm:text-xs md:text-xs lg:text-sm xl:text-base text-grey-darker text-center mt-5 pl-2">
          {{ $t('user.subwelcome') }}
        </p>
      </div>
    </div>
    <div class="shadow bg-white flex-shrink-0 xs:w-3/4 sm:w-3/4 md:w-1/2 max-w-md">
      <div class="overflow-auto xs:py-5 sm:py-10 md:py-10 lg:py-16 flex flex-col items-center">
        <div class="form-container w-3/4">
          <ChoosePassword
            v-if="currStep === 1"
            :loader="loader"
            :form="form"
            :rules="rules"
            @next-form="() => currStep += 1"
          />
          <CreateAccount
            v-else-if="currStep === 2"
            :loader="loader"
            :form="form"
            :rules="rules"
            :default-currencies="defaultCurrencies"
            :regions="regions"
            @back="currStep -= 1"
            @submit="handleSubmit"
          />
        </div>
      </div>
    </div>
  </section>
  <section v-else>
    <ConfirmationMessage
      :loader="loader"
      :form="form"
    />
  </section>
</template>

<script>
import { mapActions } from 'vuex';
import Moment from 'moment';
import http from '../../../../utils/http';
import ChoosePassword from './partials/ChoosePassword.vue';
import ConfirmationMessage from './partials/ConfirmationMessage.vue';
import CreateAccount from './partials/CreateAccount.vue';

export default {
  name: 'Activations',

  components: {
    ChoosePassword,
    CreateAccount,
    ConfirmationMessage,
  },

  data() {
    const validateAccountName = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('validations.required', { value: this.$t('fields.account_name') })));
      } else if (!value.match(/^[a-zA-Z0-9]*$/g)) {
        callback(new Error(this.$t('validations.alphanumeric', { value: this.$t('fields.account_name') })));
      } else if (value.length <= 5 || value.length >= 254) {
        callback(new Error(this.$t('validations.between_char_limit', { value: '5 to 255' })));
      } else {
        callback();
      }
    };

    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('validations.required', { value: this.$t('fields.password') })));
      } else if (value.length <= 7 || value.length >= 49) {
        callback(new Error(this.$t('validations.between_char_limit', { value: '8 to 50' })));
      } else if (!value.match(/^(?=.*[a-zA-Z])/g)) {
        callback(new Error(this.$t('validations.atleast_one_letter')));
      } else if (!value.match(/^(?=.*[0-9])/g)) {
        callback(new Error(this.$t('validations.atleast_one_number')));
      } else if (!value.match(/^(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])/g)) {
        callback(new Error(this.$t('validations.atleast_one_special_char')));
      } else {
        callback();
      }
    };

    const regions = [
      {
        name: 'Asia Pacific',
        id: 0,
        description: this.$t('user.tooltip.asia_region'),
      },
      {
        name: 'Indonesia',
        id: 1,
        description: this.$t('user.tooltip.indonesia_region'),
      },
      {
        name: 'Europe',
        id: 2,
        description: this.$t('user.tooltip.europe_region'),
      },
      {
        name: 'North America',
        id: 3,
        description: this.$t('user.tooltip.us_region'),
      },
    ];

    return {
      loader: false,
      isSubmitted: false,
      token: '',
      currStep: 1,
      form: {
        account: '',
        fname: '',
        lname: '',
        email: '',
        password: '',
        referralCode: '',
        acceptTermsConditions: false,
        subscribe: false,
        currencyValue: 'EUR',
        currencyLabel: 'Euro',
        country: 'SG',
        countryName: 'Singapore',
        regionId: 0,
      },
      rules: {
        account: [
          {
            validator: validateAccountName,
            required: true,
            trigger: 'blur',
          },
        ],
        password: [
          {
            validator: validatePass,
            type: 'password',
            required: true,
            trigger: 'blur',
          },
        ],
      },
      defaultCurrencies: [],
      urlTarget: '',
      regions,
    };
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.validateActivationToken();
      vm.getCurrencies();
      vm.getIPLocation();
    });
  },

  watch: {
    'form.country': {
      handler(country) {
        const curr = this.defaultCurrencies.find(i => i.Country === country);
        if (curr) {
          this.form.currencyValue = curr.DefaultAccountCurrency;
          this.form.currencyLabel = curr.CurrencyName;
          this.form.country = country;
          this.form.countryName = curr.CountryName;
          this.form.regionId = curr.RegionId;
        }
      },
    },
  },

  mounted() {
    this.token = this.$route.params.token;

    // google ads
    const googleAnalytics = document.createElement('script');
    googleAnalytics.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=AW-974507262');
    googleAnalytics.setAttribute('id', 'googleAdsScript');
    document.head.appendChild(googleAnalytics);

    if (Object.keys(this.$route.query).includes('utm_source') && this.$route.query.utm_source === 'adwords') {
      window.dataLayer = window.dataLayer || [];
      this.gtag('js', new Date());
      this.gtag('config', 'AW-974507262');
    }
  },

  beforeDestroy() {
    const el = document.getElementById('googleAdsScript');
    if (el) { el.remove(); }
  },

  methods: {
    ...mapActions({
      getIP: 'common/getIP',
      getDefaultCurrencies: 'common/getDefaultCurrencies',
    }),

    // google ads method
    gtag(args) {
      window.dataLayer.push(args);
    },

    getIPLocation() {
      this.getIP()
        .then(async (res) => {
          const curr = this.defaultCurrencies.find(i => i.Country === res.country_code);
          if (curr) {
            this.form.currencyValue = curr.DefaultAccountCurrency;
            this.form.currencyLabel = curr.CurrencyName;
            this.form.country = res.country_code;
            this.form.countryName = curr.CountryName;
            this.form.regionId = curr.RegionId;
          }
        })
        .catch((err) => {
          this.$showError(this, err);
        });
    },

    verifyReferralCode(code) {
      this.loader = true;
      http.v1.get(`auth/verify-referral-code?referralCode=${code}`)
        .then(() => {
          this.currStep += 1;
          this.loader = false;
        }).catch((err) => {
          // if (window.Bugsnag) {
          //   window.Bugsnag.notify(err);
          // }

          this.$showError(this, err, { useMessage: this.$t('errors.ref_code') });

          this.currStep = 1;

          this.loader = false;
        });
    },

    handleSubmit() {
      this.loader = true;
      this.isSubmitted = true;
      this.$recaptchaLoaded()
        .then(() => this.$recaptcha('register'))
        .then(gtoken => http.v1.post('auth/register', {
          token: this.token,
          account: this.form.account,
          password: this.form.password,
          firstname: this.form.fname,
          lastname: this.form.lname,
          username: this.form.email,
          optIn: this.form.subscribe,
          currency: this.form.currencyValue,
          country: this.form.country,
          referralCode: this.form.referralCode,
          timezone: this.$jstz3.determine().name(),
          gToken: gtoken,
          regionId: this.form.regionId,
          acceptTermsConditions: this.form.acceptTermsConditions,
        }))
        .then((results) => {
          if (results.data) {
            const { data } = results;
            const { UrlTarget } = data;

            if (UrlTarget) {
              this.urlTarget = UrlTarget;
            }
          }

          const startTime = localStorage.getItem('sign_up_started');
          const endTime = Moment();

          let timeToActivate = '00:00:00';

          if (startTime) {
            const hrs = Moment.utc(endTime.diff(startTime)).format('HH');
            const min = Moment.utc(endTime.diff(startTime)).format('mm');
            const sec = Moment.utc(endTime.diff(startTime)).format('ss');
            timeToActivate = [hrs, min, sec].join(':');
          }

          const region = this.regions.find(r => parseInt(r.id, 10) === parseInt(this.form.regionId, 10));


          if (region) {
            const { name: dataCenterSelected } = region;
            const { track } = this.$telemetry;

            track('account_activated', { uiArea: 'activation_page', timeToActivate, dataCenterSelected, userEmail: this.form.email });

            localStorage.removeItem('sign_up_started');
          }

          this.loader = false;
          // Analytics
          window.analytics.identify(this.form.email, {
            ...this.$route.query,
            email: this.form.email,
          });

          window.analytics.track('Account Activated', {
            token: this.form.token || '',
            email: this.form.email || '',
          });

          this.$message({
            message: this.$t('success.validated', { value: this.$t('fields.account') }),
            type: 'success',
          });

          this.submitHubspotForm();
          this.currStep += 1;

          // Event snippet for google ads source
          const callback = function () {
            if (typeof (url) !== 'undefined') {
              window.location = url;
            }
          };
          this.gtag('event', 'conversion', {
            send_to: 'AW-974507262/7gRpCNfswG8Q_pnX0AM',
            event_callback: callback,
          });
        })
        .catch((err) => {
          // if (window.Bugsnag) {
          //   window.Bugsnag.notify(err);
          // }

          if (err && err.message && err.message.match(/referral|code|referralcode|invalid/i)) {
            this.$showError(this, err, { useMessage: this.$t('errors.ref_code') });
          } else {
            this.$showError(this, err);
          }

          this.currStep = 1;

          this.loader = false;
          this.isSubmitted = false;
        });
    },
    goToLogin() {
      this.$router.push({ path: '/' });
    },
    validateActivationToken() {
      http.v1.post('signups/validate', {
        token: this.$route.params.token,
      })
        .then((d) => {
          const { data } = d;
          if (data) {
            this.form.email = data.Email;
          } else {
            this.$router.push({ path: '/' });
          }
        })
        .catch((err) => {
          // if (window.Bugsnag) {
          //   window.Bugsnag.notify(err);
          // }
          // this.$message.error(err.message || this.$t('errors.default'));
          this.$showError(this, err);
          this.$router.push({ path: '/' });
        });
    },

    async submitHubspotForm() {
      const {
        email, account, lname, fname, country,
      } = this.form;
      let ip = '';

      await this.getIP().then((res) => {
        // eslint-disable-next-line prefer-destructuring
        ip = res.ip;
      });

      const formBody = {
        fields: [
          {
            name: 'email',
            value: email,
          },
          {
            name: 'account_name',
            value: account,
          },
          {
            name: 'firstname',
            value: fname,
          },
          {
            name: 'lastname',
            value: lname,
          },
          {
            name: 'country',
            value: country,
          },
          {
            name: 'env',
            value: window.location.hostname,
          },
          {
            name: 'ip',
            value: ip,
          },
        ],
        context: {
          ipAddress: ip,
        },
      };

      http.post('https://api.hsforms.com/submissions/v3/integration/submit/587546/45c59729-59e2-4c5a-946b-f5dfab1aa515', formBody)
        .then(() => {})
        .catch(() => {});
    },

    getCurrencies() {
      return this.getDefaultCurrencies().then((res) => {
        this.defaultCurrencies = res;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.signup-section {
  background-color: #7e808a;
}
.form-container {
  max-width: 40rem;
}
</style>
