<template>
  <section class="w-full h-full flex">
    <div class="w-1/2 xs:hidden sm:hidden md:hidden lg:flex flex-col justify-center flex-shrink-1">
      <div class="p-10">
        <h1 class="text-5xl text-black leading-normal">
          {{ $t('user.welcome') }}
        </h1>
        <p class="text-base text-grey-darker mt-5 pl-2">
          {{ $t('user.subwelcome') }}
        </p>
      </div>
    </div>
    <div class="shadow bg-white h-full flex-shrink-0 lg:rounded-l-lg xs:w-full sm:w-full md:w-full lg:w-1/2">
      <div class="h-full overflow-auto xs:p-5 sm:p-10 md:p-10 lg:p-20 flex flex-col justify-center items-center">
        <el-form
          ref="ruleForm"
          label-position="top"
          :model="form"
          :rules="rules"
          class="form-container xl:w-3/4"
          @submit.native.prevent="handleSubmit('ruleForm')"
        >
          <div class="text-center mb-12">
            <Logo />
          </div>
          <h2 class="text-black text-lg mb-3">
            {{ $t('user.reset_password') }}
          </h2>

          <div class="block">
            <p class="mb-1 text-sm font-medium text-grey-darker required">
              {{ $t('user.new_password') }}
            </p>
            <el-form-item prop="pass">
              <el-input
                v-model="form.pass"
                type="password"
                :placeholder="$t('user.new_password')"
                @keyup.native="check"
              />
            </el-form-item>
          </div>

          <div class="block">
            <p class="mb-1 text-sm font-medium text-grey-darker required">
              {{ $t('user.confirm_password') }}
            </p>
            <el-form-item prop="checkPass">
              <el-input
                v-model="form.checkPass"
                type="password"
                :placeholder="$t('user.reenter_password')"
              />
            </el-form-item>
          </div>

          <div class="block w-full">
            <el-form-item>
              {{ $t('user.password_labels[3]') }}
              <el-progress
                :show-text="false"
                :percentage="strength"
                :status="status"
                class="pb1 mt-1 w-64"
              />
            </el-form-item>
          </div>
          <br>
          <el-form-item>
            <el-button
              :loading="loader"
              :disabled="strength < 75 || form.pass !== form.checkPass"
              type="primary"
              native-type="submit"
              class="login-btn w-full"
            >
              {{ $t('user.change_password') }}
            </el-button>
          </el-form-item>

          <hr>

          <el-form-item>
            <el-button
              :loading="loader"
              class="login-btn w-full"
              @click="login()"
            >
              {{ $t('user.signin') }}
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex';
import singleSpa from 'single-spa';
import Logo from '../components/Logo.vue';
import http from '../../../utils/http';

export default {
  name: 'ResetPassword',

  components: {
    Logo,
  },

  data() {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('validations.required', { value: this.$t('fields.password') })));
      } else if (value.length <= 7 || value.length >= 49) {
        callback(new Error(this.$t('validations.between_char_limit', { value: '8 to 50' })));
      } else if (!value.match(/^(?=.*[a-zA-Z])/g)) {
        callback(new Error(this.$t('validations.atleast_one_letter')));
      } else if (!value.match(/^(?=.*[0-9])/g)) {
        callback(new Error(this.$t('validations.atleast_one_number')));
      } else if (!value.match(/^(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])/g)) {
        callback(new Error(this.$t('validations.atleast_one_special_char')));
      } else if (this.strength < 75) {
        callback(new Error(this.$t('validations.weak_password')));
      } else if (this.form.checkPass !== '') {
        this.$refs.ruleForm.validateField('checkPass');
      } else {
        callback();
      }
    };
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('validations.input_again', { value: this.$t('fields.password') })));
      } else if (value !== this.form.pass) {
        callback(new Error(this.$t('validations.not_matched', { value: this.$t('fields.password') })));
      } else {
        callback();
      }
    };

    return {
      score: 0,
      loader: false,
      loginEmail: '',
      form: {
        pass: '',
        checkPass: '',
      },

      rules: {
        pass: [
          {
            validator: validatePass,
            type: 'password',
            required: true,
            trigger: 'blur',
          },
        ],
        checkPass: [
          {
            validator: validatePass2,
            type: 'password',
            required: true,
            trigger: 'blur',
          },
        ],
      },
    };
  },

  computed: {
    strength() {
      return this.score * 25;
    },
    status() {
      if (this.score < 3) {
        return 'weak';
      }

      if (this.score < 4) {
        return 'good';
      }

      return 'strong';
    },
  },

  beforeRouteEnter(to, from, next) {
    next(vm => vm.validateResetToken());
  },

  methods: {
    ...mapActions({
      resetPassword: 'users/resetPassword',
    }),

    handleSubmit() {
      // Show spinner
      this.loader = true;

      this.resetPassword({
        token: this.$route.params.token,
        password: this.form.pass,
        email: this.loginEmail,
      })
        .then(() => {
          this.loader = false;
          this.showMessage(this.$t('success.updated', { value: this.$t('fields.password') }), 'success');
          singleSpa.navigateToUrl('/');
          // window.location = '/';
        })
        .catch((err) => {
          // if (window.Bugsnag) {
          //   window.Bugsnag.notify(e);
          // }

          let msg = '';
          this.loader = false;

          if (err.message && err.message.match(/8 char/i)) {
            msg = this.$t('validations.between_char_limit', { value: '8 to 50' });
          } else if (err.message && err.message.match(/INVALID_TOKEN/)) {
            msg = this.$t('errors.token_expired');
          } else {
            msg = this.$t('errors.500');
          }

          if (msg) {
            this.$showError(this, err, { useMessage: msg });
          } else {
            this.$showError(this, err);
          }

          // this.showMessage(msg, 'error');
        });
    },

    showMessage(message, type) {
      this.$message({
        title: 'Error',
        message,
        type,
      });
    },

    validateResetToken() {
      // check if coming from password expiry
      if (this.$route.params.feedback) {
        this.showMessage(`${this.$t('user.password_expiring[2]')}. ${this.$t('user.password_expiring[3]')}`, 'error');
      }

      http.v1.post('auth/validate-reset-token', {
        token: this.$route.params.token,
      }).then((result) => {
        const { Login } = result.data;
        this.loginEmail = Login;
      })
        .catch(() => {
          this.$router.push({ path: '/' });
        });
    },

    login() {
      this.$router.push({ path: '/' });
    },
    check() {
      const result = this.$zxcvbn3(this.form.pass);
      this.score = result.score;
    },
  },
};
</script>

<style>
 .form-container .el-form-item__label {
    padding: 0;
 }
</style>
