import Login from './views/Login.vue';
import ForgotPassword from './views/ForgotPassword.vue';
import ResetPassword from './views/ResetPassword.vue';
import SetPassword from './views/SetPassword.vue';
import Activations from './views/Activations/index.vue';
import VerifyAuth from './views/VerifyAuth.vue';
import SSOLogin from './views/SSOLogin.vue';
import Signup from './views/Signup.vue';


export default [
  {
    path: '/',
    name: 'login',
    component: Login,
  },

  {
    path: '/sso',
    name: 'sso',
    component: SSOLogin,
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPassword,
  },
  {
    path: '/reset-password/:token',
    name: 'reset-password',
    component: ResetPassword,
  },
  {
    path: '/set-password',
    name: 'set-password',
    component: SetPassword,
  },
  {
    path: '/activations/:token',
    name: 'activations',
    component: Activations,
  },
  {
    path: '/verify',
    name: 'verfiy',
    component: VerifyAuth,
  },
  {
    path: '/signup',
    name: 'signup',
    component: Signup,
  },
];
