import Vue from 'vue';
import Vuex from 'vuex';

import users from './users';
import common from './common';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

const store = new Vuex.Store({
  state: {
    errors: [],
    loggedInLoading: false,
  },

  getters: {
    getLoggedInLoading: st => st.loggedInLoading,
  },

  mutations: {
    API_ERROR(state, payload) {
      // if (window.Bugsnag) {
      //   window.Bugsnag.notify(payload);
      // }
      state.errors.push(payload); // eslint-disable-line no-param-reassign
    },

    LOGGED_IN_LOADING(state, loading) {
      state.loggedInLoading = loading; // eslint-disable-line no-param-reassign
    },
  },

  modules: {
    common,
    users,
  },

  strict: debug,
});

export default store;
