<template>
  <svg
    class="login-logo"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 302 139"
  >
    <path
      d="M194.051 51.161h-32.563l-7.505 14.288c-1.022 2.213-2.725 4.712-2.725 4.712h-.341s-1.532-2.499-2.724-4.712l-7.506-14.288h-32.546l25.578 39.482-25.473 39.518h31.995l8.633-16.498c.852-1.532 2.043-4.502 2.043-4.502h.341s1.192 2.97 2.043 4.502l8.775 16.498h32.023l-25.472-39.518 25.424-39.482M49.807 110.055c-9.022 0-16.164-6.954-16.164-15.599 0-7.331 3.947-12.969 7.142-16.352 13.157 6.014 25.186 10.525 25.186 18.231 0 8.834-6.202 13.72-16.164 13.72zm.94-81.007c8.834 0 14.096 4.887 14.096 12.405 0 8.27-3.571 14.473-4.699 16.54-11.84-5.075-21.614-9.585-21.614-18.419 0-5.827 3.759-10.526 12.217-10.526zm33.079 40.598c1.316-1.692 13.157-16.164 13.157-30.636C96.983 14.012 76.496.104 51.499.104 20.111.104 6.39 19.275 6.39 38.822c0 11.465 5.075 19.547 12.217 25.749C14.472 67.766 0 79.983 0 97.275 0 118.701 17.104 139 49.807 139c33.267 0 49.807-20.487 49.807-40.973 0-13.533-6.766-22.179-15.788-28.381zM252.079 110.055c-9.021 0-16.163-6.954-16.163-15.599 0-7.331 3.947-12.969 7.142-16.352 13.156 6.014 25.185 10.525 25.185 18.231 0 8.834-6.202 13.72-16.164 13.72zm.94-81.007c8.834 0 14.097 4.887 14.097 12.405 0 8.27-3.572 14.473-4.7 16.54-11.84-5.075-21.614-9.585-21.614-18.419 0-5.827 3.759-10.526 12.217-10.526zm33.08 40.598c1.315-1.692 13.156-16.164 13.156-30.636 0-24.998-20.487-38.906-45.484-38.906-31.388 0-45.108 19.171-45.108 38.718 0 11.465 5.074 19.547 12.217 25.749-4.135 3.195-18.608 15.412-18.608 32.704 0 21.426 17.104 41.725 49.807 41.725 33.268 0 49.807-20.487 49.807-40.973 0-13.533-6.766-22.179-15.787-28.381z"
      class="logo__path"
      :fill="colorValue"
      clip-rule="evenodd"
      fill-rule="evenodd"
      stroke="none">
    </path>
  </svg>
</template>
<script>
export default {
  name: 'Logo',

  props: {
    colorValue: {
      type: String,
      default: '#cb2233'
    },
  },
};
</script>

<style scoped>
.login-logo {
  width: 5rem;
}
</style>
