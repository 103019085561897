<template>
  <section class="w-full h-full flex">
    <div class="w-1/2 xs:hidden sm:hidden md:hidden lg:flex flex-col justify-center flex-shrink-1">
      <div class="p-10">
        <h1 class="text-5xl text-black leading-normal">
          {{ $t('user.welcome') }}
        </h1>
        <p class="text-base text-grey-darker mt-5 pl-2">
          {{ $t('user.subwelcome') }}
        </p>
      </div>
    </div>
    <div class="shadow bg-white h-full flex-shrink-0 lg:rounded-l-lg xs:w-full sm:w-full md:w-full lg:w-1/2">
      <div class="h-full overflow-auto xs:p-5 sm:p-10 md:p-10 lg:p-20 flex flex-col justify-center items-center">
        <el-form
          ref="formRef"
          label-position="top"
          :model="form"
          :rules="rules"
          class="form-container xl:w-3/4"
          @submit.native.prevent="login"
        >
          <div class="text-center mb-12">
            <Logo />
          </div>
          <h2 class="text-black text-lg mb-3">
            {{ $t('user.sso_sign_on') }}
          </h2>
          <p class="mb-5 text-sm font-medium text-grey-darker">
            {{ $t('user.org_sso') }}
          </p>

          <div class="block">
            <p class="mb-1 text-sm font-medium text-grey-darker required">
              {{ $t('user.work_email') }}
            </p>
            <el-form-item prop="email">
              <el-input
                v-model="form.email"
                :placeholder="$t('fields.email')"
              />
            </el-form-item>
          </div>

          <el-form-item>
            <el-button
              type="primary"
              class="login-btn w-full"
              :disabled="loginLoading"
              :loading="loginLoading"
              native-type="submit"
            >
              {{ $t('user.signin') }}
            </el-button>
          </el-form-item>

          <hr>

          <el-form-item>
            <el-button
              class="login-btn w-full"
              :disabled="loginLoading"
              @click="goToLogin"
            >
              {{ $t('user.sign_in_without_sso') }}
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex';
import Logo from '../components/Logo.vue';

export default {
  name: 'SSOLogin',

  components: {
    Logo,
  },

  data() {
    const self = this;

    return {
      loginLoading: false,
      form: {
        email: '',
      },
      rules: {
        email: [
          { required: true, message: self.$t('validations.required', { value: self.$t('fields.email') }), trigger: 'change' },
          { type: 'email', message: self.$t('validations.valid', { value: self.$t('fields.email') }), trigger: 'change' },
        ],
      },
    };
  },

  mounted() {
    if (this.$route.query.email) {
      const { email } = this.$route.query;

      this.ssoLogin(email);
    }
  },

  methods: {
    ...mapActions({
      loginSSO: 'common/loginSSO',
    }),

    login() {
      this.$refs.formRef.validate((valid) => {
        if (!valid) {
          return;
        }

        const { email } = this.form;

        this.ssoLogin(email);
      });
    },

    showMessage(message, type) {
      let msg = this.$t('errors.default');
      const title = this.$t('user.sso_failed');

      if (message === 'User suspended') {
        msg = this.$t('errors.saml_user_suspended');
      } else if (message === 'SAML login is disabled.') {
        msg = this.$t('errors.saml.disabled');
      } else if (message === 'SAML instance is not configured') {
        msg = this.$t('errors.saml.not_configured');
      } else if (message === 'User not found') {
        msg = this.$t('errors.saml_user_not_found');
      }

      this.$msgbox({
        title,
        message: msg,
        type,
      });
    },

    ssoLogin(email) {
      this.loginLoading = true;

      this.loginSSO({ email })
        .then(({ idpLoginUrl }) => {
          if (idpLoginUrl) {
            // Sanitize url
            const url = new URL(idpLoginUrl);

            window.location.replace(url.href);
          } else {
            this.$showError(this, new Error('No IDP Login URL detected'));
          }
        })
        .catch((err) => {
          this.$showError(this, err, { isLogin: true });
        })
        .finally(() => {
          this.loginLoading = false;
        });
    },

    goToLogin() {
      this.$router.push({ path: '/' });
    },

    fixUrl(url) {
      return url.replace('/login', '');
    },
  },
};
</script>

<style>
 .form-container .el-form-item__label {
    padding: 0;
 }

 .backdrop-l {
   backdrop-filter: blur(10px);
 }
</style>
