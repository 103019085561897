//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import jwtDecode from 'jwt-decode';
import { mapActions } from 'vuex';
import CodeInput from '../../navbar/partials/CodeInput.vue';
import Logo from '../components/Logo.vue';

import http from '../../../utils/http';
import tfIcon from '../../assets/two-fa-step2.svg';

export default {
  name: 'VerifyAuth',

  components: {
    CodeInput,
    Logo,
  },

  data() {
    const self = this;
    return {
      tfIcon,
      isLoginUntil7days: false,
      otpCode: '',
      isViaSMS: false,
      otpData: {},
      selectedOtp: {},
      loading: false,
      durInSecs: 60 * 2,
      time: 0,
      tick: 1000,
      loadingText: self.$t('wait.loading'),
      timer: null,
    };
  },

  beforeRouteEnter(to, from, next) {
    next(vm => vm.getOTPData());
  },

  beforeRouteLeave(to, from, next) {
    next(vm => vm.removeOTPData());
  },

  methods: {
    ...mapActions({
      getAuthUser: 'common/getAuthUser',
      addSubscriptionInfoToCache: 'common/addSubscriptionInfoToCache',
    }),
    use() {
      this.isViaSMS = !this.isViaSMS;

      const type = this.isViaSMS ? 'SMS_OTP' : 'AUTHENTICATOR_APP';

      this.selectedOtp = this.otpData.TwoFA.find(v => v.type === type);

      if (!this.otpData.SMS && this.isViaSMS) {
        this.sendOTPViaSMS();
      }
    },

    login() {
      this.$router.push({ path: '/' });
    },

    verify() {
      const req = {
        refId: this.otpData.RefId,
        otpCode: this.otpCode,
        otpType: this.isViaSMS ? 'SMS_OTP' : 'AUTHENTICATOR_APP',
        rememberUntil: this.isLoginUntil7days,
      };

      if (this.isViaSMS) {
        req.uid = this.otpData.SMS ? this.otpData.SMS.uid : '';
      }

      this.loadingText = this.$t('wait.verifying');
      this.loading = true;

      http.v1.post('auth/verify-code', {
        ...req,
        rolesVersion: 2,
      }, { withCredentials: true, crossDomain: true })
        .then(async (response) => {
          const { status } = response.data;

          if (status && status === 'success') {
            // Set authorization token in local storage
            localStorage.setItem('WWW-Authenticate', response.headers.authorization);
            const user = jwtDecode(response.headers.authorization);
            await this.addSubscriptionInfoToCache({
              accountId: user.AccountId,
            });

            // this.$router.push({ path: '/' });
            this.getAuthUser()
              .then(({ data }) => {
                // update local storage
                localStorage.setItem('CPV3_User', JSON.stringify(data));
                this.loading = false;

                window.location = '/';
              }).catch(() => {
                this.loading = false;
              });
          } else {
            this.loading = false;
            // this.showMessage(this.$t('errors.default'), 'error');
            this.$showError(this, new Error(this.$t('errors.default')));
          }
        })
        .catch((err) => {
          // if (window.Bugsnag) {
          //   window.Bugsnag.notify(err);
          // }

          this.loading = false;
          this.$showError(this, err);
          // this.showMessage(err.message || this.$t('errors.default'), 'error');
        });
    },

    getOTPData() {
      try {
        const otp = JSON.parse(localStorage.getItem('OTP-VERIFY'));

        if (otp) {
          localStorage.removeItem('OTP-VERIFY');

          this.otpData = otp;

          this.selectedOtp = this.otpData.TwoFA.find(v => v.default);

          this.isViaSMS = this.selectedOtp.type === 'SMS_OTP';

          if (this.isViaSMS) {
            this.sendOTPViaSMS();
          }
        } else {
          window.location = '/';
          // this.$router.push({ path: '/' });
        }
      } catch (e) {
        // do nothing
      }
    },

    sendOTPViaSMS(resend) {
      const { RefId: refId } = this.otpData;

      this.loadingText = this.$t('wait.verifying');

      this.loading = true;

      this.$recaptchaLoaded()
        .then(() => this.$recaptcha('generate_login_otp'))
        .then(token => http.v1.post('auth/send-code', {
          refId,
          gToken: token,
        }))
        .then(({ data }) => {
          this.otpData.SMS = data;
          this.loading = false;

          if (resend) {
            this.$notify({
              title: this.$t('success.verification_sent[0]'),
              message: this.$t('success.verification_sent[1]'),
              type: 'success',
            });
          }

          this.startTimer();
        })
        .catch(() => {
          this.loading = false;
          this.showMessage(this.$t('errors.default'), 'error');
        });
    },

    resend() {
      if (this.time !== 0) {
        return 0;
      }

      this.stopTimer();
      this.sendOTPViaSMS(true);
      return 1;
    },

    startTimer() {
      this.time = this.durInSecs * this.tick;

      if (!this.timer) {
        this.timer = setInterval(() => {
          if (this.time > 0) {
            this.time = this.time - this.tick;
          } else {
            clearInterval(this.timer);
          }
        }, this.tick);
      }
    },

    stopTimer() {
      this.time = 0;
      clearInterval(this.timer);
      this.timer = null;
    },

    removeOTPData() {
      this.otpData = {};
      this.defaultOtp = {};
      this.isViaSMS = false;
      localStorage.removeItem('OTP-VERIFY');

      this.stopTimer();
    },

    showMessage(message, type) {
      this.$message({
        showClose: true,
        message,
        type,
      });
    },
  },
};
