<template>
  <div
    id="login"
    class="login h-screen w-screen relative flex overflow-auto"
  >
    <locale-select />
    <RouterView />
  </div>
</template>

<script>
import LocaleSelect from './LocaleSelect.vue';


export default {
  components: {
    LocaleSelect,
  },

  beforeDestroy() {
    this.$el.className = '';
    this.$telemetry.teardownTelemetry();
  },

};
</script>

<style scoped>
  .login {
    background-color:#F6F6F6;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 100%;
  }
 .login-btn {
    width: 7rem;
 }
</style>

<style lang="scss">
.login {
  hr {
    border-bottom: solid 1px var(--gray);
    margin: 20px auto;
  }
}
</style>
