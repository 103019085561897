//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import http from '../../../utils/http';
import Logo from '../components/Logo.vue';

export default {
  name: 'ForgotPassword',

  components: {
    Logo,
  },

  data() {
    const self = this;
    return {
      isSubmit: false,

      loader: false,

      form: {
        username: '',
      },

      token: null,

      rules: {
        username: [
          { required: true, message: self.$t('validations.required', { value: self.$t('fields.email') }), trigger: 'change' },
          { type: 'email', message: self.$t('validations.valid', { value: self.$t('fields.email') }), trigger: 'change' },
        ],
      },
    };
  },

  methods: {
    handleSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          return;
        }

        // Show spinner
        this.loader = true;

        this.$recaptchaLoaded()
          .then(() => this.$recaptcha('forgot_password'))
          .then(token => http.v1.post('auth/forgot-password', {
            email: this.form.username,
            gToken: token,
            version: 'v3',
          })).then(() => {
            this.isSubmit = true;
            this.loader = false;
          })
          .catch((err) => {
            const { data = {} } = err;

            if (data.error && data.error.match(/sso_configured/i)) {
              const email = this.form.username;

              this.$showError(this, err, { useMessage: this.$t('errors.saml_enabled'), reloadUrl: `/login/sso?email=${email}` });
            } else {
              this.$showError(this, err, { useMessage: this.$t('errors.invalid'), reloadUrl: '/login/forgot-password' });
            }
          })
          .finally(() => {
            this.loader = false;
          });
      });
    },


    login() {
      this.$router.push({ path: '/' });
    },

    emailForm() {
      this.form.username = '';
      this.isSubmit = false;
    },
  },
};
