// import handleErrors from '../../../common/handle-api-errors';
import http from '../../../../utils/http';

export default {
  validateResetPasswordToken({ commit }, payload) {
    return new Promise((resolve, reject) => {
      http.v1.post('auth/validate-reset-token', {
        ...payload,
      })
        .then(({ data }) => {
          if (data.Result === 'VALID_TOKEN') {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  resetPassword({ commit }, payload) {
    return new Promise((resolve, reject) => {
      http.v1.post('auth/reset-password', {
        ...payload,
      })
        .then((data) => {
          if (data.status === 200) {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },
};
