<template>
  <section class="w-full h-full flex flex-col items-center">
    <div class="w-1/2 lg:flex justify-center flex-col flex-shrink-1 items-center">
      <div class="p-10">
        <h1 class="text-center xs:text-lg sm:text-xl md:text-2xl lg:text-4xl text-black leading-normal">
          {{ $t('user.welcome') }}
        </h1>
        <p class="xs:text-xs sm:text-xs md:text-xs lg:text-sm xl:text-base text-grey-darker text-center mt-5 pl-2">
          {{ $t('user.subwelcome') }}
        </p>
      </div>
    </div>
    <div class="shadow bg-white flex-shrink-0 xs:w-3/4 sm:w-3/4 md:w-1/2 max-w-md">
      <div class="h-full overflow-auto xs:py-5 sm:py-10 md:py-10 lg:py-16 flex flex-col items-center">
        <el-form
          v-if="!isSubmit"
          ref="ruleForm"
          label-position="top"
          :model="form"
          :rules="rules"
          class="form-container w-3/4"
          @submit.native.prevent="handleSubmit('ruleForm')"
        >
          <div class="text-center mb-12">
            <Logo />
          </div>
          <h2 class="text-black text-lg mb-5">
            {{ $t('user.sign_up') }}
          </h2>

          <div class="block">
            <p class="mb-1 text-sm font-medium text-grey-darker required">
              {{ $t('fields.email') }}
            </p>
            <el-form-item
              class="leading-normal"
              prop="username"
            >
              <el-input
                v-model="form.username"
                :placeholder="$t('user.placeholders[0]')"
              />
            </el-form-item>
          </div>

          <el-form-item class="m-0">
            <el-button
              :loading="loader"
              type="primary"
              native-type="submit"
              class="login-btn w-full mt-5"
            >
              {{ $t('user.register_email') }}
            </el-button>
          </el-form-item>

          <router-link
            to="/"
            class="mt-12 flex justify-center text-grey-darker no-underline text-xs inline-block"
          >
            {{ $t('user.already_have_account') }}
            &nbsp;
            <span class="text-blue">{{ $t('user.login_here') }}</span>
          </router-link>
        </el-form>
        <div
          v-else
          class="form-container lg:px-10"
        >
          <div class="text-center mb-12">
            <Logo />
          </div>
          <h2 class="text-black text-lg mb-5">
            {{ $t('user.check_email[0]') }}
          </h2>
          <p class="mb-5 text-sm font-medium text-grey-darker leading-normal">
            {{ $t('user.check_email[1]') }}
            <span class="text-black font-bold">{{ form.username || 'your email' }}</span>.
            {{ $t('user.check_email[5]') }}
          </p>

          <p class="mb-5 text-sm font-medium text-grey-darker leading-normal">
            {{ $t('user.check_email[6]') }}
          </p>

          <el-button
            class="login-btn w-full"
            @click.prevent="emailForm"
          >
            {{ $t('user.check_email[4]') }}
          </el-button>

          <hr>

          <a
            class="flex justify-center text-blue no-underline text-xs inline-block cursor-pointer"
            @click="login()"
          >
            {{ $t('user.go_back_login') }}
          </a>

          <router-link
            to="/"
            class="mt-10 flex justify-center text-grey-darker no-underline text-xs inline-block"
          >
            {{ $t('user.already_have_account') }}
            &nbsp;
            <span class="text-blue">{{ $t('user.login_here') }}</span>
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import http from '../../../utils/http';
import Logo from '../components/Logo.vue';

export default {
  name: 'ForgotPassword',

  components: {
    Logo,
  },

  data() {
    const self = this;
    return {
      isSubmit: false,

      loader: false,

      form: {
        username: '',
      },

      rules: {
        username: [
          { required: true, message: self.$t('validations.required', { value: self.$t('fields.email') }), trigger: 'change' },
          { type: 'email', message: self.$t('validations.valid', { value: self.$t('fields.email') }), trigger: 'change' },
        ],
      },
    };
  },

  methods: {
    resetField() {
      this.isSubmit = false;
      this.form.username = '';
      this.token = null;
    },

    handleSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          return;
        }

        // Show spinner
        this.loader = true;

        // add recaptcha
        this.$recaptchaLoaded()
          .then(() => this.$recaptcha('signup'))
          .then(token => http.v1.post('signups', {
            email: this.form.username,
            gToken: token,
          }))
          .then(() => {
            this.isSubmit = true;
            localStorage.removeItem('sign_up_started');

            const { track } = this.$telemetry;

            track('user_signed_up', { uiArea: 'sign_up_page', userEmail: this.form.username });

            localStorage.setItem('sign_up_started', new Date());
            this.submitHubspotForm();
          })
          .catch((err) => {
            this.$showError(this, err, { reloadUrl: '/login/signup' });
            // if (window.Bugsnag) {
            //   window.Bugsnag.notify(err);
            // }
            const message = this.$t(`apiErrors.${err.error}`) || this.$t('errors.default');
            
            this.$message({
              title: err && err.error.indexOf('signup') ? 'Too Many Requests' : 'Error',
              message: message,
              type: 'error',
            });
          })
          .finally(() => {
            this.loader = false;
          });
      });
    },
    emailForm() {
      this.form.username = '';
      this.isSubmit = false;
    },
    login() {
      this.$router.push({ path: '/' });
    },
    submitHubspotForm() {
      const {
        username: email,
      } = this.form;

      http.post('https://forms.hubspot.com/uploads/form/v2/587546/af4548c6-a415-4951-bd6a-5a22ea2fb06a', {
        email,
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
        .then(() => {})
        .catch((err) => {
          if (window.Bugsnag) {
            window.Bugsnag.notify(err);
          }
        });
    },
  },
};
</script>

<style>
 .form-container .el-form-item__label {
    padding: 0;
 }
</style>
