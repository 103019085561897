//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Logo from '../../../components/Logo.vue';

export default {
  name: 'CreateAccount',

  components: { Logo },

  props: {
    loader: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
      required: true,
    },
    rules: {
      type: Object,
      default: () => {},
    },
    defaultCurrencies: {
      type: Array,
      default: () => [],
    },
    regions: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      editMode: false,
      defaultRegionId: 0,
    };
  },

  computed: {
    regionName() {
      if (this.regions) {
        return this.getRegion(this.form.regionId)?.name || this.getRegion(this.defaultRegionId)?.name || '';
      }
      return '';
    },
  },

  methods: {
    toggleEdit() {
      this.editMode = !this.editMode;
    },

    handleValidateForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          return;
        }

        this.$emit('submit');
      });
    },

    getRegion(regionId) {
      return this.regions.find(r => parseInt(r.id, 10) === parseInt(regionId, 10));
    }
  },
};

