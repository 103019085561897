//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Logo',

  props: {
    colorValue: {
      type: String,
      default: '#cb2233'
    },
  },
};
