<template>
  <div
    class=" black-container flex justify-center items-center h-screen w-screen"
  >
    <div
      class="white-container flex flex-col justify-center items-center bg-white max-w-sm"
    >
      <div v-if="loader">
        <div
          v-loading="true"
          class="w-full flex justify-center mt-8 mb-20"
        />
        <span v-if="countDown">{{ $t('user.signing_up') }}</span>
        <span v-else>{{ $t('user.logging_in') }}</span>
      </div>
      <div
        v-else
        class="flex flex-col items-center"
      >
        <img
          class="w-4/5"
          src="../../../../assets/signup-successful.svg"
          alt="SignUpSuccess"
        >
        <span class="mt-6 mb-4">{{ $t('user.yoohoo') }}</span>
        <span>{{ $t('user.successful_signup') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'ConfirmationMessage',

  props: {
    loader: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      countDown: 3, // 3 seconds
    };
  },

  watch: {
    async loader() {
      if (!this.loader) {
        this.countDownTimer();
      } else {
        const response = await this.loginUser({
          username: this.form.email,
          password: this.form.password,
          rolesVersion: 2,
        });

        this.handleSuccess(response);
        window.location.replace('/');
      }
    },
  },

  methods: {
    ...mapActions({
      getAuthUser: 'common/getAuthUser',
      loginUser: 'common/login',
    }),

    async countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      } else {
        this.loader = true;
      }
    },
    handleSuccess: async (response = {}) => {
      const { status, authorization } = response;

      if (status && status === 'success') {
        localStorage.setItem('WWW-Authenticate', authorization);

        try {
          const { data } = await this.getAuthUser();
          localStorage.setItem('CPV3_User', JSON.stringify(data));

          // segment tracking
          if (window.analytics) {
            window.analytics.identify(data.Email, {
              Email: data.Email,
              AccountUid: data.AccountUid,
              SelectedLocale: localStorage.getItem('SELECTED_LOCALE'),
            });
          }

          if (window.Bugsnag) {
            window.Bugsnag.setUser(data.UserId);
          }

          window.location.replace('/');
        } catch (err) {
          this.$showError(this, err);
        }
      } else {
        throw new Error(this.$t('errors.default'));
      }

      return 0;
    },
  },
}; </script>


<style>
.black-container {
    background-color: #666;
}

.white-container {
    width: 40%;
    height: 50%;
    max-height: 500px;
}
</style>
