<template>
  <section class="w-full h-full flex">
    <div class="w-1/2 xs:hidden sm:hidden md:hidden lg:flex flex-col justify-center flex-shrink-1">
      <div class="p-10">
        <h1 class="text-5xl text-black leading-normal">
          {{ $t('user.welcome') }}
        </h1>
        <p class="text-base text-grey-darker mt-5 pl-2">
          {{ $t('user.subwelcome') }}
        </p>
      </div>
    </div>
    <div class="shadow bg-white h-full flex-shrink-0 lg:rounded-l-lg xs:w-full sm:w-full md:w-full lg:w-1/2">
      <div class="h-full overflow-auto xs:p-5 sm:p-10 md:p-10 lg:p-20 flex flex-col justify-center items-center">
        <div
          v-if="otpData && Object.keys(otpData).length"
          v-loading="loading"
          :element-loading-text="loadingText"
          class="xl:w-3/4"
        >
          <div class="text-center mb-12">
            <Logo />
          </div>
          <h2 class="text-black text-lg mb-5">
            {{ isViaSMS ? $t('user.verification_type[0]') : $t('user.verification_type[1]') }}
          </h2>
          <p class="mt-5 text-grey-dark leading-normal">
            {{ $t('user.to_login') }}
            {{
              isViaSMS ?
                `${ $t('user.verification_type[2]')} ${selectedOtp.msisdn}`
                : $t('user.verification_type[3]')
            }}
          </p>

          <div class="block mt-10">
            <p class="mb-3 text-sm font-medium text-grey-darker text-center required">
              {{ $t('user.verfication_code') }}
            </p>
            <div class="flex justify-center">
              <CodeInput v-model="otpCode" />
            </div>
          </div>

          <div
            v-if="isViaSMS"
            class="w-full text-center mt-4"
          >
            <a
              :class="time !== 0 ? 'text-grey' : 'text-blue'"
              class="no-underline text-sm inline-block"
              @click.prevent="resend()"
            >
              {{ $t('user.resend_code') }}  {{ time ? `(${time / 1000})` : '' }}
            </a>
          </div>

          <div class="flex items-center mt-10">
            <el-checkbox
              v-model="isLoginUntil7days"
              :disabled="loading"
              class="text-xs text-grey-dark ml-2"
            >
              {{ $t('user.remember_7_days') }}
            </el-checkbox>
          </div>

          <el-button
            :disabled="!otpCode"
            :loading="loading"
            type="primary"
            class="w-full mt-5"
            @click="verify"
          >
            {{ $t('user.verify') }}
          </el-button>

          <hr>

          <el-button
            :loading="loading"
            type="info"
            class="w-full"
            @click="login()"
          >
            {{ $t('user.go_back_login') }}
          </el-button>

          <div
            v-if="otpData && Object.keys(otpData).length && otpData.TwoFA.length > 1"
            class="mt-5 text-center"
          >
            <a
              class="text-blue no-underline text-sm inline-blockrcursor-pointer"
              @click.prevent="use()"
            >
              {{ $t('user.verify_using') }}
              <bold>{{ !isViaSMS ? $t('user.verification_type[0]') : $t('user.verification_type[1]') }}</bold>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import jwtDecode from 'jwt-decode';
import { mapActions } from 'vuex';
import CodeInput from '../../navbar/partials/CodeInput.vue';
import Logo from '../components/Logo.vue';

import http from '../../../utils/http';
import tfIcon from '../../assets/two-fa-step2.svg';

export default {
  name: 'VerifyAuth',

  components: {
    CodeInput,
    Logo,
  },

  data() {
    const self = this;
    return {
      tfIcon,
      isLoginUntil7days: false,
      otpCode: '',
      isViaSMS: false,
      otpData: {},
      selectedOtp: {},
      loading: false,
      durInSecs: 60 * 2,
      time: 0,
      tick: 1000,
      loadingText: self.$t('wait.loading'),
      timer: null,
    };
  },

  beforeRouteEnter(to, from, next) {
    next(vm => vm.getOTPData());
  },

  beforeRouteLeave(to, from, next) {
    next(vm => vm.removeOTPData());
  },

  methods: {
    ...mapActions({
      getAuthUser: 'common/getAuthUser',
      addSubscriptionInfoToCache: 'common/addSubscriptionInfoToCache',
    }),
    use() {
      this.isViaSMS = !this.isViaSMS;

      const type = this.isViaSMS ? 'SMS_OTP' : 'AUTHENTICATOR_APP';

      this.selectedOtp = this.otpData.TwoFA.find(v => v.type === type);

      if (!this.otpData.SMS && this.isViaSMS) {
        this.sendOTPViaSMS();
      }
    },

    login() {
      this.$router.push({ path: '/' });
    },

    verify() {
      const req = {
        refId: this.otpData.RefId,
        otpCode: this.otpCode,
        otpType: this.isViaSMS ? 'SMS_OTP' : 'AUTHENTICATOR_APP',
        rememberUntil: this.isLoginUntil7days,
      };

      if (this.isViaSMS) {
        req.uid = this.otpData.SMS ? this.otpData.SMS.uid : '';
      }

      this.loadingText = this.$t('wait.verifying');
      this.loading = true;

      http.v1.post('auth/verify-code', {
        ...req,
        rolesVersion: 2,
      }, { withCredentials: true, crossDomain: true })
        .then(async (response) => {
          const { status } = response.data;

          if (status && status === 'success') {
            // Set authorization token in local storage
            localStorage.setItem('WWW-Authenticate', response.headers.authorization);
            const user = jwtDecode(response.headers.authorization);
            await this.addSubscriptionInfoToCache({
              accountId: user.AccountId,
            });

            // this.$router.push({ path: '/' });
            this.getAuthUser()
              .then(({ data }) => {
                // update local storage
                localStorage.setItem('CPV3_User', JSON.stringify(data));
                this.loading = false;

                window.location = '/';
              }).catch(() => {
                this.loading = false;
              });
          } else {
            this.loading = false;
            // this.showMessage(this.$t('errors.default'), 'error');
            this.$showError(this, new Error(this.$t('errors.default')));
          }
        })
        .catch((err) => {
          // if (window.Bugsnag) {
          //   window.Bugsnag.notify(err);
          // }

          this.loading = false;
          this.$showError(this, err);
          // this.showMessage(err.message || this.$t('errors.default'), 'error');
        });
    },

    getOTPData() {
      try {
        const otp = JSON.parse(localStorage.getItem('OTP-VERIFY'));

        if (otp) {
          localStorage.removeItem('OTP-VERIFY');

          this.otpData = otp;

          this.selectedOtp = this.otpData.TwoFA.find(v => v.default);

          this.isViaSMS = this.selectedOtp.type === 'SMS_OTP';

          if (this.isViaSMS) {
            this.sendOTPViaSMS();
          }
        } else {
          window.location = '/';
          // this.$router.push({ path: '/' });
        }
      } catch (e) {
        // do nothing
      }
    },

    sendOTPViaSMS(resend) {
      const { RefId: refId } = this.otpData;

      this.loadingText = this.$t('wait.verifying');

      this.loading = true;

      this.$recaptchaLoaded()
        .then(() => this.$recaptcha('generate_login_otp'))
        .then(token => http.v1.post('auth/send-code', {
          refId,
          gToken: token,
        }))
        .then(({ data }) => {
          this.otpData.SMS = data;
          this.loading = false;

          if (resend) {
            this.$notify({
              title: this.$t('success.verification_sent[0]'),
              message: this.$t('success.verification_sent[1]'),
              type: 'success',
            });
          }

          this.startTimer();
        })
        .catch(() => {
          this.loading = false;
          this.showMessage(this.$t('errors.default'), 'error');
        });
    },

    resend() {
      if (this.time !== 0) {
        return 0;
      }

      this.stopTimer();
      this.sendOTPViaSMS(true);
      return 1;
    },

    startTimer() {
      this.time = this.durInSecs * this.tick;

      if (!this.timer) {
        this.timer = setInterval(() => {
          if (this.time > 0) {
            this.time = this.time - this.tick;
          } else {
            clearInterval(this.timer);
          }
        }, this.tick);
      }
    },

    stopTimer() {
      this.time = 0;
      clearInterval(this.timer);
      this.timer = null;
    },

    removeOTPData() {
      this.otpData = {};
      this.defaultOtp = {};
      this.isViaSMS = false;
      localStorage.removeItem('OTP-VERIFY');

      this.stopTimer();
    },

    showMessage(message, type) {
      this.$message({
        showClose: true,
        message,
        type,
      });
    },
  },
};
</script>

<style lang="scss">
  .two-fa {
    .back-arrow {
      font-size: 24px;
      font-weight: bold;
      color: #409EFF;
      left: 6.5%;
      top: 6%;
      cursor: pointer;
      text-decoration: none;
    }
  }
</style>
