import actions from './actions';

export default {
  namespaced: true,

  state: {},

  mutations: {},

  actions,
};
