import Vue from 'vue';
import VueRouter from 'vue-router';
import singleSpa from 'single-spa';
import singleSpaVue from 'single-spa-vue';
import ElementUI, { Message } from 'element-ui';
import jwtDecode from 'jwt-decode';
import VueI18n from 'vue-i18n';

import Bugsnag from '@bugsnag/js';

import { VueReCaptcha } from 'vue-recaptcha-v3';

import VueCookies from 'vue-cookies';

// import { TelemetryClient } from '@8x8/telemetry-client';
// import { TelemetryService } from '@8x8/telemetry-mfe';

// import locale from 'element-ui/lib/locale/lang/en';
// import enLocale from 'element-ui/lib/locale/lang/en';
// import idLocale from 'element-ui/lib/locale/lang/id';
// import zhLocale from 'element-ui/lib/locale/lang/zh-CN';

import { sync } from 'vuex-router-sync';

// import en from '../../locale/lang/en.json';
// import id from '../../locale/lang/id.json';
import languages from '../../locale/lang/languages.json';

import VueJstz from './plugins/vue-jstz';
import VueZxcvbn from './plugins/vue-zxcvbn';
import ErrorUIHandler from '../common/handle-error-msg';
import http from '../../utils/http';
import lang from '../../utils/lang';
import App from './App.vue';
import store from './store';

import env from '../../utils/process_env';

import routes from './router';


Vue.use(VueJstz);
Vue.use(VueZxcvbn);
Vue.use(VueRouter);

Vue.use(VueI18n);
Vue.use(VueCookies, { expires: '3y' });

const router = new VueRouter({
  base: '/login',
  mode: 'history',
  routes,
});

const langs = languages.map(v => v.code);
const selectedLang = lang.getUserLanguage(langs, lang.getDefaultLanguage());

if (!localStorage.getItem('SELECTED_LOCALE')) {
  localStorage.setItem('SELECTED_LOCALE', selectedLang);
}

const locale = lang.getSelectedLanguage() || selectedLang;

document.querySelector('html').setAttribute('lang', locale);

const i18n = new VueI18n({
  locale, // set locale
  fallbackLocale: lang.getDefaultLanguage(),
});

sync(store, router);

const showMessage = (message, type = 'warning') => {
  Message({
    message,
    type,
  });
};

router.beforeEach((to, from, next) => {
  const at = localStorage.getItem('WWW-Authenticate');

  // Redirect to overview page if token is available
  if (at && window.location.pathname.match(/^\/login/i)) {
    window.location = '/';
    return 0;
  }

  const pathRegex = /^(\/signup|\/activations|\/forgot-password|\/sso|\/reset-password|\/set-password|\/verify|\/)\/?/i;
  const { path } = to;
  const matchResult = path.match(pathRegex);
  const match = matchResult && matchResult.length ? matchResult[0] : '';

  next();

  if (Object.keys(to.query).length !== 0 && to.query.token
    && (!to.query.impersonate || to.query.impersonate !== 'true')) {
    localStorage.setItem('WWW-Authenticate', to.query.token);
  }

  switch (match) {
  case '/':
    // check if token query is set in URL
    if (at) {
      store.commit('LOGGED_IN_LOADING', true, { root: true });
      http.v1.get('auth/check?rolesVersion=2')
        .then(() => {
          store.commit('LOGGED_IN_LOADING', false, { root: true });
          // singleSpa.navigateToUrl('/');
          window.location = '/';
        })
        .catch((e) => {
          Bugsnag.notify(e);

          store.commit('LOGGED_IN_LOADING', false, { root: true });
          localStorage.removeItem('WWW-Authenticate');
          localStorage.removeItem('cpv3Impersonate');
          localStorage.removeItem('CPV3_User');
          singleSpa.navigateToUrl('/login');
        });
    } else {
      // check if impersonate
      // eslint-disable-next-line no-lonely-if
      if (Object.keys(to.query).length !== 0 && to.query.token
          && ((to.query.impersonate && to.query.impersonate === 'true')
          || (to.query.connectImpersonate && to.query.connectImpersonate === 'true'))) {
        // remove existing WWW-Authenticate
        localStorage.removeItem('WWW-Authenticate');
        localStorage.removeItem('cpv3Impersonate');
        localStorage.removeItem('CPV3_User');

        store.commit('LOGGED_IN_LOADING', true, { root: true });

        const {
          exp = 0,
          AccountId: accountId,
          LoginMethod: loginMethod,
        } = jwtDecode(to.query.token);
        const currentTime = Math.floor(Date.now() / 1000);
        if (currentTime > exp) {
          store.commit('LOGGED_IN_LOADING', false, { root: true });
          showMessage('Token is expired', 'error');
          singleSpa.navigateToUrl('/login');
          throw new Error('Token is expired');
        }

        // login impersonate
        http.v1.post('auth/login/other', {
          userId: to.query.userId,
          cpv3Impersonate: true,
          token: to.query.token,
          rolesVersion: 2,
          accountUid: to.query.accountUid,
        }, { withCredentials: false, crossDomain: true })
          .then(async () => {
            store.commit('LOGGED_IN_LOADING', false, { root: true });
            localStorage.setItem('WWW-Authenticate', to.query.token);

            if ((loginMethod && loginMethod !== 'SAML') || !loginMethod) {
              localStorage.setItem('cpv3Impersonate', true);
            }

            await store.dispatch('common/addSubscriptionInfoToCache', { accountId });

            store.dispatch('common/getAuthUser').then(({ data }) => {
              localStorage.setItem('CPV3_User', JSON.stringify(data));
              store.commit('LOGGED_IN_LOADING', false, { root: true });
              window.location = '/';
            });

            // singleSpa.navigateToUrl('/');
            // window.location = '/';
          })
          .catch((e) => {
            Bugsnag.notify(e);
            store.commit('LOGGED_IN_LOADING', false, { root: true });
            singleSpa.navigateToUrl('/login');
            throw new Error('Impersonation failed');
          });
      } else {
        // singleSpa.navigateToUrl('/login');
        next();
      }
    }
    break;
  case '/forgot-password':
    next();
    break;
  case '/sso':
    next();
    break;
  case '/reset-password':
    next('/');
    break;
  case '/set-password':
    next();
    break;
  case '/activations':
    next();
    break;
  case '/verify':
    next();
    break;
  default:
    next();
  }

  return 0;
});

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    el: '#login',
    i18n,
    store,
    router,
    render(h) {
      // check root config for locale messages
      Object.keys(this.localeMessages).forEach((v) => {
        this.$i18n.setLocaleMessage(v, this.localeMessages[v]);
      });

      Vue.use(VueReCaptcha, { siteKey: env.googleRecaptchaKey });

      Vue.use(ElementUI, {
        i18n: (key, value) => this.$i18n.t(key, value),
      });

      const { localeMessages, langUtil, telemetry } = this;

      Vue.prototype.$telemetry = telemetry;

      Vue.use(ErrorUIHandler);

      return h(App, { props: { localeMessages, langUtil, telemetry } });
    },
  },
});

export const bootstrap = [
  vueLifecycles.bootstrap,
];

export const mount = [
  vueLifecycles.mount,
];

export const unmount = [
  vueLifecycles.unmount,
];
