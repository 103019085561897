<template>
  <section class="w-full h-full flex">
    <div class="w-1/2 xs:hidden sm:hidden md:hidden lg:flex flex-col justify-center flex-shrink-1">
      <div class="p-10">
        <h1 class="text-5xl text-black leading-normal">
          {{ $t('user.welcome') }}
        </h1>
        <p class="text-base text-grey-darker mt-5 pl-2">
          {{ $t('user.subwelcome') }}
        </p>
      </div>
    </div>
    <div class="shadow bg-white h-full flex-shrink-0 lg:rounded-l-lg xs:w-full sm:w-full md:w-full lg:w-1/2">
      <div class="h-full overflow-auto xs:p-5 sm:p-10 md:p-10 lg:p-20 flex flex-col justify-center items-center">
        <el-form
          ref="ruleForm"
          label-position="top"
          :model="form"
          :rules="rules"
          class="form-container xl:w-3/4"
          @submit.native.prevent="handleSubmit('ruleForm')"
        >
          <div class="text-center mb-12">
            <Logo />
          </div>
          <h2 class="text-black text-lg mb-3">
            {{ $t('user.hello') }} 👋
          </h2>
          <p class="mb-5 text-sm font-medium text-grey-darker">
            {{ $t('user.set_new_password') }}
          </p>

          <div class="block">
            <p class="mb-1 text-sm font-medium text-grey-darker required">
              {{ $t('fields.password') }}
            </p>
            <el-form-item prop="pass">
              <el-input
                v-model="form.pass"
                type="password"
                :placeholder="$t('fields.password')"
                @keyup.native="check"
              />
              <el-progress
                class="pb1 mt-1 w-64"
                :show-text="false"
                :percentage="strength"
                :status="status"
              />
            </el-form-item>
          </div>

          <div class="block">
            <p class="mb-1 text-sm font-medium text-grey-darker required">
              {{ $t('user.confirm_password') }}
            </p>
            <el-form-item prop="checkPass">
              <el-input
                v-model="form.checkPass"
                type="password"
                :placeholder="$t('user.confirm_password')"
              />
            </el-form-item>
          </div>


          <el-form-item prop="optIn">
            <el-checkbox
              v-model="form.optIn"
              class="break-all mt-5 block whitespace-normal flex"
            >
              {{ $t('user.marketing_opt_in') }}
            </el-checkbox>
          </el-form-item>

          <el-form-item>
            <el-button
              :loading="loader"
              :disabled="strength < 75 || form.pass !== form.checkPass"
              type="primary"
              native-type="submit"
              class="login-btn w-full"
            >
              {{ $t('user.lets_go') }}
            </el-button>
          </el-form-item>

          <div>
            <p class="text-xs text-grey leading-normal">
              {{ $t('user.clicking_agree', { action: $t('user.lets_go') }) }}
              <a
                class="text-blue"
                rel="nofollow noopener"
                href="https://wavecell.com/terms-of-use"
                target="_blank"
              >{{ $t('user.terms[0]') }}</a>
              &nbsp;
              ,
              <a
                class="text-blue"
                rel="nofollow noopener"
                href="https://wavecell.com/privacy-policy/"
                target="_blank"
              >{{ $t('user.terms[1]') }}</a>
              &nbsp;
              {{ $t('user.terms[2]') }}
              &nbsp;
              <a
                class="text-blue"
                rel="nofollow noopener"
                href="https://wavecell.com/acceptable-use-policy/"
                target="_blank"
              >{{ $t('user.terms[3]') }}</a>
              &nbsp;
              {{ $t('user.terms[4]') }}
            </p>
          </div>
        </el-form>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex';

import jwtDecode from 'jwt-decode';
import Logo from '../components/Logo.vue';

export default {
  name: 'SetPassword',

  components: {
    Logo,
  },

  data() {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('validations.required', { value: this.$t('fields.password') })));
      } else if (value.length <= 7 || value.length >= 49) {
        callback(new Error(this.$t('validations.between_char_limit', { value: '8 to 50' })));
      } else if (!value.match(/^(?=.*[a-zA-Z])/g)) {
        callback(new Error(this.$t('validations.atleast_one_letter')));
      } else if (!value.match(/^(?=.*[0-9])/g)) {
        callback(new Error(this.$t('validations.atleast_one_number')));
      } else if (!value.match(/^(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])/g)) {
        callback(new Error(this.$t('validations.atleast_one_special_char')));
      } else if (this.strength < 75) {
        callback(new Error(this.$t('validations.weak_password')));
      } else if (this.form.checkPass !== '') {
        this.$refs.ruleForm.validateField('checkPass');
      } else {
        callback();
      }
    };
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('validations.input_again', { value: this.$t('fields.password') })));
      } else if (value !== this.form.pass) {
        callback(new Error(this.$t('validations.not_matched', { value: this.$t('fields.password') })));
      } else {
        callback();
      }
    };

    return {
      score: 0,

      token: '',

      user: {},

      fromInvite: false,

      loader: false,

      form: {
        pass: '',
        checkPass: '',
        optIn: false,
      },

      rules: {
        pass: [
          {
            validator: validatePass,
            type: 'password',
            required: true,
            trigger: 'blur',
          },
        ],
        checkPass: [
          {
            validator: validatePass2,
            type: 'password',
            required: true,
            trigger: 'blur',
          },
        ],
        optIn: [
          {
            required: false,
            trigger: 'blur',
          },
        ],
      },
    };
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.getToken();
    });
  },

  computed: {
    strength() {
      return this.score * 25;
    },
    status() {
      if (this.score < 3) {
        return 'weak';
      }

      if (this.score < 4) {
        return 'good';
      }

      return 'strong';
    },
  },

  methods: {
    ...mapActions({
      validateResetPasswordToken: 'users/validateResetPasswordToken',
      resetPassword: 'users/resetPassword',
      login: 'common/login',
      getAuthUser: 'common/getAuthUser',
      addSubscriptionInfoToCache: 'common/addSubscriptionInfoToCache',
    }),

    handleSubmit(formName) {
      if (this.$refs[formName].validate()) {
        const {
          pass,
          optIn,
        } = this.form;

        // Show spinner
        this.loader = true;

        if (this.fromInvite) {
          this.resetPassword({
            token: this.token,
            password: pass,
            fromInvite: true,
            email: this.user.Login,
            optIn,
          })
            .then(() => {
              // Auto login
              this.login({
                username: this.user.Login,
                password: pass,
                rolesVersion: 2,
              })
                .then(async (response) => {
                  const { authorization = '' } = response;

                  // Set authorization token in local storage
                  localStorage.setItem('WWW-Authenticate', authorization);

                  // Add subscription info to user's cache
                  const user = jwtDecode(authorization);
                  await this.addSubscriptionInfoToCache({
                    accountId: user.AccountId,
                  });

                  // Disable loader


                  this.getAuthUser()
                    .then(({ data }) => {
                      // update local storage
                      localStorage.setItem('CPV3_User', JSON.stringify(data));
                      this.loader = false;

                      window.location = '/';
                    }).catch(() => {
                      this.loader = false;
                    });

                  // singleSpa.navigateToUrl('/');
                })
                .catch((e) => {
                  if (window.Bugsnag) {
                    window.Bugsnag.notify(e);
                  }
                  this.showMessage(e.message, 'error');

                  // Disable loader
                  this.loader = false;
                });
            })
            .catch((err) => {
              // if (window.Bugsnag) {
              //   window.Bugsnag.notify(e);
              // }
              let msg = '';
              this.loader = false;

              if (err.message.match(/8 char/i)) {
                msg = this.$t('validations.between_char_limit', { value: '8 to 50' });
              } else if (err.message.match(/INVALID_TOKEN/)) {
                msg = this.$t('errors.token_expired');
              }

              if (msg) {
                this.$showError(this, err, { useMessage: msg });
              } else {
                this.$showError(this, err);
              }

              // this.showMessage(msg, 'error');
            });
        } else {
          // this.showMessage(this.$t('errors.resend_invite_email'), 'error');
          this.$showError(this, new Error(this.$t('errors.resend_invite_email')), { useMessage: this.$t('errors.resend_invite_email') });
          // If user is not invited go to login
          this.$router.push({ path: '/' });
        }
      }
    },

    getToken() {
      const { token: rt } = this.$route.query;

      if (rt) {
        this.validateResetPasswordToken({ token: rt })
          .then((resp) => {
            this.user = resp;
            this.fromInvite = true;
            this.token = rt;
          })
          .catch((err) => {
            let msg = '';

            switch (err.message) {
            case 'INVALID_TOKEN':
              msg = this.$t('errors.password_reset_link_invalid');
              break;
            case 'TOKEN_EXPIRED':
              msg = this.$t('errors.password_reset_link_expired');
              break;
            default:
            }


            if (msg) {
              this.$showError(this, err, { useMessage: msg });
            } else {
              this.$showError(this, err);
            }

            // this.showMessage(msg, 'error');
          });
      } else {
        const oldUser = JSON.parse(window.localStorage.getItem('oldUser'));

        if (!oldUser) {
          this.$router.push({ path: '/' });
          return;
        }

        const { token } = oldUser;
        this.token = token;
      }
    },

    showMessage(message, type) {
      this.$message({
        title: 'Error',
        message,
        type,
      });
    },

    check() {
      const result = this.$zxcvbn3(this.form.pass);
      this.score = result.score;
    },
  },
};
</script>

<style>
 .form-container {}

 .form-container .el-form-item__label {
    padding: 0;
 }
</style>
