import DOMPurify from 'dompurify';

const getStatus = (st = '') => {
  let style = 'text-gray-darkest border border-grey bg-grey-light';
  let statusColor = 'info';

  let status = st;

  switch (st.toLowerCase()) {
  case 'new': {
    status = 'open';
    statusColor = 'warning';
    style = 'text-blue-darkest border border-blue bg-blue-light';
    break;
  }
  case 'open': {
    statusColor = 'warning';
    style = 'text-blue-darkest border border-blue bg-blue-light';
    break;
  }
  case 'solved': {
    statusColor = 'success';
    style = 'text-green-darkest border border-green bg-green-light';
    break;
  }

  case 'pending': {
    status = 'awaiting your reply';
    statusColor = '';
    style = 'text-red-darkest border border-red bg-red-light';
    break;
  }

  case 'hold': {
    statusColor = 'warning';
    style = 'text-blue-darkest border border-blue bg-blue-light';
    status = 'open';
    break;
  }

  case 'closed': {
    status = 'closed';
    break;
  }

  default:
  }


  return { style, status, statusColor };
};

const getLocale = () => (localStorage.getItem('SELECTED_LOCALE') || 'en').toLowerCase();

const getSubject = (subj) => {
  if (subj.includes('|')) {
    const s = subj.split('|');

    return s[s.length - 1];
  }

  return subj;
};

const getDescription = (d) => {
  if (d.includes('***')) {
    const s = d.trim().split('***');

    return s[s.length - 1];
  }

  return d;
};

const getBody = (htmlbody, plainbody) => {
  // console.log('subjecrt', subject);
  // eslint-disable-next-line no-useless-escape
  if (plainbody.includes('***')) {
    const s = plainbody.trim().split('***');

    return s[s.length - 1];
  }

  return htmlbody;
};

const getSafeHtmlTags = () => ['strong', 'em', 'b', 'i', 'p', 'code', 'pre', 'samp', 'kbd', 'var', 'sub', 'sup', 'dfn', 'cite',
  'small', 'address', 'hr', 'br', 'id', 'div', 'span', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6',
  'ul', 'ol', 'li', 'dl', 'dt', 'dd', 'abbr', 'a', 'img', 'blockquote',
  'del', 'ins', 'table', 'thead', 'tbody', 'tfoot', 'tr', 'th', 'td', 'colgroup', 'iframe'];

const getSafeHtmlAttributes = () => ['href', 'src', 'width', 'height', 'alt', 'cite', 'datetime', 'title', 'class', 'name',
  'xml:lang', 'abbr', 'target', 'border', 'rel', 'frameborder', 'allowfullscreen'];


const sanitizeHtml = (c) => {
  const content = c.replace(/href/g, 'target="_blank" href');

  return DOMPurify.sanitize(content,
    { ALLOWED_TAGS: getSafeHtmlTags(), ALLOWED_ATTR: getSafeHtmlAttributes() });
};

const getFileIcon = (filename) => {
  let icon = 'description';
  const file = ['.csv', '.txt', '.json'];

  const pdf = ['.pdf'];

  const document = ['.doc', 'docx', '.ppt', '.xlsx', '.xls'];

  const zip = ['.zip'];

  const image = ['.svg', '.jpeg', '.jpg', '.png', '.gif', '.webp'];

  const video = ['.mp4', '.mpeg', '.wmv', '.mov'];

  const audio = ['.mp3', '.wav'];

  const fileSplit = filename.split('.');

  if (fileSplit.length > 1) {
    const fileExt = fileSplit[fileSplit.length - 1].toLowerCase();


    if (file.toString().includes(fileExt)) {
      icon = 'text_snippet';
    }

    if (document.toString().includes(fileExt)) {
      icon = 'description';
    }

    if (pdf.toString().includes(fileExt)) {
      icon = 'picture_as_pdf';
    }

    if (zip.toString().includes(fileExt)) {
      icon = 'folder_zip';
    }

    if (image.toString().includes(fileExt)) {
      icon = 'image';
    }

    if (video.toString().includes(fileExt)) {
      icon = 'video_file';
    }

    if (audio.toString().includes(fileExt)) {
      icon = 'audio_file';
    }
  }

  return icon;
};

const getSenderIdStatus = (st) => {
  let style = 'text-gray-darkest border border-grey bg-grey-light';
  let statusColor = 'warning';

  let status = 'support.tickets.statuses.sender_id.in_progress';

  switch (st) {
  case 0: {
    statusColor = 'warning';
    style = 'text-blue-darkest border border-blue bg-blue-light';
    status = 'support.tickets.statuses.sender_id.in_progress';
    break;
  }

  case 1: {
    statusColor = 'success';
    style = 'text-green-darkest border border-green bg-green-light';
    status = 'support.tickets.statuses.sender_id.registered';
    break;
  }

  case 2: {
    statusColor = '';
    style = 'text-red-darkest border border-red bg-red-light';
    status = 'support.tickets.statuses.sender_id.cancelled';
    break;
  }

  default:
  }

  return { style, status, statusColor };
};

const inPlaceSanitize = (c) => {
  const test = c.match(/(\b)(on\S+)(\s*)=|javascript|<(|\/|[^\/>][^>]+|\/[^>][^>]+)>/gi); // eslint-disable-line no-useless-escape
  if (test !== null) {
    return '/';
  }
  return DOMPurify.sanitize(c, { IN_PLACE: true }); // https://github.com/cure53/DOMPurify/issues/288
};

export default {
  getStatus,
  getLocale,
  getSubject,
  getDescription,
  getSafeHtmlTags,
  getSafeHtmlAttributes,
  sanitizeHtml,
  inPlaceSanitize,
  getBody,
  getFileIcon,
  getSenderIdStatus,
};
