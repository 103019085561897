//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';
import Logo from '../components/Logo.vue';

export default {
  name: 'SSOLogin',

  components: {
    Logo,
  },

  data() {
    const self = this;

    return {
      loginLoading: false,
      form: {
        email: '',
      },
      rules: {
        email: [
          { required: true, message: self.$t('validations.required', { value: self.$t('fields.email') }), trigger: 'change' },
          { type: 'email', message: self.$t('validations.valid', { value: self.$t('fields.email') }), trigger: 'change' },
        ],
      },
    };
  },

  mounted() {
    if (this.$route.query.email) {
      const { email } = this.$route.query;

      this.ssoLogin(email);
    }
  },

  methods: {
    ...mapActions({
      loginSSO: 'common/loginSSO',
    }),

    login() {
      this.$refs.formRef.validate((valid) => {
        if (!valid) {
          return;
        }

        const { email } = this.form;

        this.ssoLogin(email);
      });
    },

    showMessage(message, type) {
      let msg = this.$t('errors.default');
      const title = this.$t('user.sso_failed');

      if (message === 'User suspended') {
        msg = this.$t('errors.saml_user_suspended');
      } else if (message === 'SAML login is disabled.') {
        msg = this.$t('errors.saml.disabled');
      } else if (message === 'SAML instance is not configured') {
        msg = this.$t('errors.saml.not_configured');
      } else if (message === 'User not found') {
        msg = this.$t('errors.saml_user_not_found');
      }

      this.$msgbox({
        title,
        message: msg,
        type,
      });
    },

    ssoLogin(email) {
      this.loginLoading = true;

      this.loginSSO({ email })
        .then(({ idpLoginUrl }) => {
          if (idpLoginUrl) {
            // Sanitize url
            const url = new URL(idpLoginUrl);

            window.location.replace(url.href);
          } else {
            this.$showError(this, new Error('No IDP Login URL detected'));
          }
        })
        .catch((err) => {
          this.$showError(this, err, { isLogin: true });
        })
        .finally(() => {
          this.loginLoading = false;
        });
    },

    goToLogin() {
      this.$router.push({ path: '/' });
    },

    fixUrl(url) {
      return url.replace('/login', '');
    },
  },
};
