//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import languages from '../../locale/lang/languages.json';

export default {
  name: 'LocaleSelect',

  data() {
    const defaultLang = localStorage.getItem('SELECTED_LOCALE') || 'en';
    const s = languages.find(v => v.code === defaultLang);

    return {
      languages,
      selected: s && s.code ? s.code : 'en',
    };
  },

  methods: {
    selectLocale(command) {
      this.selected = command;
      localStorage.setItem('SELECTED_LOCALE', this.selected);

      // this.$i18n.locale = this.selected;

      window.location.href = window.location.href;
      return false;
    },

    getLocaleName(selected) {
      const s = languages.find(v => v.code === selected);
      return s.alias;
    },

    getFlag(selected) {
      const s = languages.find(v => v.code === selected);
      return s.flag;
    },

  },
};
