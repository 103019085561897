<template>
  <div class="absolute pin-t pin-r mt-5 mr-5 z-20 font-medium">
    <el-dropdown
      size="mini"
      trigger="click"
      @command="selectLocale"
    >
      <span class="el-dropdown-link">
        <span :class="`flag-icon flag-icon-${getFlag(selected)} flag-icon-squared`" />
        {{ getLocaleName(selected) }}
        <!-- <i class="el-icon-arrow-down el-icon--right" /> -->
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item
          v-for="lang in languages"
          :key="lang.code"
          :command="lang.code"
          :disabled="!Boolean(lang.enable)"
        >
          <span :class="`flag-icon flag-icon-${lang.flag} flag-icon-squared`" />
          {{ lang.alias }}
          <span
            v-if="!Boolean(lang.enable)"
            class="text-grey text-xs"
          > ({{ lang.coming_soon }}) </span>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>
<script>
import languages from '../../locale/lang/languages.json';

export default {
  name: 'LocaleSelect',

  data() {
    const defaultLang = localStorage.getItem('SELECTED_LOCALE') || 'en';
    const s = languages.find(v => v.code === defaultLang);

    return {
      languages,
      selected: s && s.code ? s.code : 'en',
    };
  },

  methods: {
    selectLocale(command) {
      this.selected = command;
      localStorage.setItem('SELECTED_LOCALE', this.selected);

      // this.$i18n.locale = this.selected;

      window.location.href = window.location.href;
      return false;
    },

    getLocaleName(selected) {
      const s = languages.find(v => v.code === selected);
      return s.alias;
    },

    getFlag(selected) {
      const s = languages.find(v => v.code === selected);
      return s.flag;
    },

  },
};
</script>
