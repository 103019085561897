//
//
//
//
//
//
//
//
//
//

import LocaleSelect from './LocaleSelect.vue';


export default {
  components: {
    LocaleSelect,
  },

  beforeDestroy() {
    this.$el.className = '';
    this.$telemetry.teardownTelemetry();
  },

};
